<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
	   根据购置的时间和预计使用期限（月）来进行统计使用到期的资产
	 </div>  
	 <el-form ref="form" :inline="true"  :model="search_key" prop="">
	     <el-form-item label="">
	         <el-input v-model="search_key.keyword" placeholder="请输入关键字搜索" style="width:200px;"></el-input>
	     </el-form-item>
	     <el-button type="primary" @click="search()">查询</el-button>
	 </el-form>
	 	<div style="margin-bottom: 10px;">
			<el-button class="btn" size="small" @click="handleCommonAdd(multipleData,9,'资产处置单')">批量处置</el-button>
		</div>
	  <el-table
	     :data="tableData" 
	     border
		row-key="id"
		@selection-change="handleSelectionChange" 
		:row-style="{height:'20px'}">
		<el-table-column
		type="selection"
		:reserve-selection="true"
		width="55">
		</el-table-column>
		<el-table-column
		    prop="asset_code"
		    label="资产编码">
		</el-table-column>
	     <el-table-column
	         prop="expire_time"
	         label="到期时间">
	     </el-table-column>
	      <el-table-column
	         prop="cate_name"
	         label="资产分类">
	     </el-table-column>
	     <el-table-column 
	         prop="name"
	         label="资产名称">
	     </el-table-column>
		 <el-table-column
		     label="资产状态">
			 <template slot-scope="scope">
				<el-tag type="success" size='mini' v-if="scope.row['status'] == 1">空闲</el-tag>
				<el-tag  size='mini' v-if="scope.row['status'] == 2">派发中</el-tag>
				<el-tag type="warning" size='mini' v-if="scope.row['status'] == 3">退库中</el-tag>
				<el-tag type="danger" size='mini' v-if="scope.row['status'] == 4">出借中</el-tag>
				<el-tag type="warning" size='mini' v-if="scope.row['status'] == 5">归还中</el-tag>
				<el-tag size='mini' v-if="scope.row['status'] == 6">变更中</el-tag>
				<el-tag type="danger" size='mini' v-if="scope.row['status'] == 7">调拨中</el-tag>
				<el-tag type="danger" size='mini'  effect="dark"  v-if="scope.row['status'] == 8">维修中</el-tag>
				<el-tag size='mini'  v-if="scope.row['status'] == 9" type="warning">处置中</el-tag>
				<el-tag size='mini' v-if="scope.row['status'] == 10">修改中</el-tag>
				<el-tag size='mini' type="danger" v-if="scope.row['status'] == 11">已报失</el-tag>
				<el-tag size='mini' v-if="scope.row['status'] == 12">在用</el-tag>
			 </template>
		 </el-table-column>
	  </el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		
		<CommonAddVue ref="commoAddDialog"></CommonAddVue>
	</div>
</template>

<script>
import CommonAddVue from "../Common/Add.vue"
export default {
	 inject:['reload'],
	 components: {
	 	CommonAddVue
	 },
    data() {
        return {
			tableData:[],
			search_key:{
				keyword:''
			},
			multipleData:[],			
			currentPage:1,
			pagesize:10,
			total:0,
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){   //获取企业数据
		    this.$api.post('AssetReport/disposal',{
				keyword:this.search_key.keyword,
				page:this.currentPage-1,
				limit:this.pagesize,
			},res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		handleCommonAdd(rows,storage_type,card_name){
			this.$api.post('/AssetDiscovery/check',{data:rows},res=>{
				if(res.code == 200){		//判断操作资产是否在盘点中
					this.$nextTick(()=>{
						this.$refs.commoAddDialog.storageType = storage_type
						this.$refs.commoAddDialog.cardName =  card_name
						this.$refs.commoAddDialog.tableData = []
						// var showStatus =  true
						this.$refs.commoAddDialog.tableData  = rows
						this.$refs.commoAddDialog.handleAdd()			
					})
				}else{
					this.$message.error(res.msg)
				}
			})
			
		},
		handleSelectionChange(val){
			this.multipleData = val
		},
		search(){
			this.currentPage = 1
			this.getData()
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.getData()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		     this.getData()
		},	
    },
}
</script>
<style scoped lang="scss">
</style>